<template>
  <div class="pb-4">
    <h2 class="text-primary">
      {{ $t('pages.auth.server.title') }} 📡
    </h2>
    <p class="text-lined">
      {{ $t('pages.auth.server.description', { brand : $config.brand.name }) }}
    </p>

    <b-row>
      <b-col
        sm="5"
        class="animate__animated animate__bounceInUp"
      >
        <div
          v-if="loader"
          class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
        >
          <b-spinner
            variant="primary"
            type="grow"
            :label="$t('therms.loading')"
          />
        </div>
        <template v-else>
          <validation-observer
            ref="serverForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2 animate__animated"
              :class="{'animate__shakeX':error}"
              @submit.prevent="submit"
            >
              <b-form-group
                :label="$t('pages.auth.server.form.server.label')"
                label-size="lg"
                label-for="server-address"
                class="mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="server"
                  vid="server"
                  rules="required"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    id="server-address"
                    v-model="serverData"
                    type="text"
                    size="lg"
                    autocapitalize="none"
                    spellcheck="false"
                    autocomplete="off"
                    autocorrect="off"
                    :state="errors.length > 0 ? false:null"
                    name="server-address"
                    :placeholder="$t(customHost ? 'pages.auth.server.form.server.placeholder-complete' : 'pages.auth.server.form.server.placeholder')"
                  />
                  <span
                    v-if="!customHost"
                    class="pl-75 host-agriplus"
                    @click="customHost = !customHost"
                  >.{{ defaultHost }}</span>
                </validation-provider>
              </b-form-group>
              <div
                v-if="customHost"
                class="mt-50 pl-25"
                @click="customHost = !customHost"
              >{{ $t('therms.custom-url') }}
              </div>

              <div class="pb-2" />

              <!-- submit buttons -->
              <touch-button
                class="btn-rounded"
                type="submit"
                color="primary"
                :disabled="invalid || error"
                :disable="invalid || error"
              >
                {{ $t('pages.auth.server.form.test-button.value') }}
              </touch-button>
            </b-form>
          </validation-observer>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import {
  BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { NAME_LOGIN } from '@/router/routes/auth'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    TouchButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // UI
      loader: false,
      error: false,

      // data
      serverData: '',
      customHost: false,

      // validation rules
      required,
    }
  },
  computed: {
    defaultHost() {
      return 'agriplus.io'
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 1000)
    },

    customHost(val) {
      const START_TAG = '####'
      const END_TAG = '$$$'

      if (!val) {
        this.serverData = (START_TAG + this.serverData.trim() + END_TAG)
          .replace(`.fr${END_TAG}`, '')
          .replace(`.com${END_TAG}`, '')
          .replace(`.org${END_TAG}`, '')
          .replace(`.net${END_TAG}`, '')
          .replace(`.info${END_TAG}`, '')
          .replace(`.pro${END_TAG}`, '')
          .replace(`.ovh${END_TAG}`, '')
          .replace(`.biz${END_TAG}`, '')
          .replace(`.eu${END_TAG}`, '')
          .replace(`.gouv${END_TAG}`, '')
          .replace(`.gov${END_TAG}`, '')
          .replace(`.edu${END_TAG}`, '')
          .replace(`.us${END_TAG}`, '')
          .replace(`.co.uk${END_TAG}`, '')
          .replace(`.uk${END_TAG}`, '')
          .replace(`.de${END_TAG}`, '')
          .replace(`.cn${END_TAG}`, '')
          .replace(`.ru${END_TAG}`, '')
          .replace(`.es${END_TAG}`, '')
          .replace(`.pt${END_TAG}`, '')
          .replace(`.jp${END_TAG}`, '')
          .replace(`.br${END_TAG}`, '')
          .replace(`.id${END_TAG}`, '')
          .replace(`.vn${END_TAG}`, '')
          .replace(`.nl${END_TAG}`, '')
          .replace(`.ca${END_TAG}`, '')
          .replace(`.io${END_TAG}`, '')
          .replace(`${END_TAG}`, '')
          .replace(`${START_TAG}`, '')
      }
    },
  },
  async mounted() {
    this.$store.commit('auth/clear')
  },
  methods: {
    async submit() {
      try {
        if (!(await this.$refs.serverForm.validate()) || this.error) {
          return
        }

        this.loader = true
        let url = ''

        if (this.customHost) {
          url = this.serverData.indexOf('http://') === -1 && this.serverData.indexOf('https://') === -1 ? `https://${this.serverData}` : `${this.serverData}`
        } else {
          url = `https://api.${this.serverData}.${this.defaultHost}`
        }

        const response = await axios.get(url)
        if (response.status !== 200
            || response.data.state !== 'on') {
          throw new Error(`Server with "${url}" not respond.`)
        }

        this.$store.commit('server/setServer', url)
        this.$http.defaults.baseURL = this.$store.getters['server/getUrl']

        await this.$router.push({
          name: NAME_LOGIN,
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/vue/pages/page-auth.scss';

.auth-wrapper {
  .auth-bg {
    background-color: #F8F8F8;
  }

  .custom-button {
    border-radius: 0.828rem;
    font-weight: 400;
    font-size: 15px;
  }

  label {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}

.host-agriplus {
  font-size: 1.15rem;
}
</style>
